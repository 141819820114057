.mssreekrishna img{
    border-radius: 28px;
}




.containerse{
     
    height:  500px;
    width: 100%;
    background-position: center;
    background-size: cover;
    background-image: url(https://i.postimg.cc/cCdXKBLb/DSC-6529.jpg);
    background-attachment: fixed;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;

  }

  .sreekrishnajewellerskarimpur{
    font-family: 'Titillium Web', sans-serif;
    font-family: 'Ubuntu', sans-serif;
  }

  .rings-karimpur{
    font-family: 'Maven Pro', sans-serif;
  }